import HttpFactory from '~/repository/factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { REPORTS, REPORTS_GENERAL } from '~/constants/apiPaths/reports/paths'
import type { ISearchableReportFields } from '~/repository/modules/reports/types/reportTypes'

class ReportModule extends HttpFactory {
  async getGeneralReport(extras: IExtrasFetch, searchCriteria: ISearchableReportFields, signal: AbortSignal | null = null): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${REPORTS}${REPORTS_GENERAL}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async getGeneralReportFile(extras: IExtrasFetch, searchCriteria: ISearchableReportFields, signal: AbortSignal | null = null): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${REPORTS}${REPORTS_GENERAL}/download?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }
}
export default ReportModule
