import { default as indexi505hxz1pNMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/brands/index.vue?macro=true";
import { default as indexUO4gTW64OCMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/companies/index.vue?macro=true";
import { default as companiesfDtM5bqvcwMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/[id]/companies.vue?macro=true";
import { default as _91id_93jWOdy22J07Meta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/[id].vue?macro=true";
import { default as indextCSkv68MfkMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/index.vue?macro=true";
import { default as index2MUHHltZAbMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/index.vue?macro=true";
import { default as indexzYzxxR7bP9Meta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordpTZODeYQodMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/forgot-password.vue?macro=true";
import { default as indexNy65mkjdGYMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/index.vue?macro=true";
import { default as paymentsLYiovItf2kMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/[id]/payments.vue?macro=true";
import { default as index0yQWu5TCQ1Meta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/index.vue?macro=true";
import { default as detailsM5XduYWuNlMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/installmentPayments/[id]/details.vue?macro=true";
import { default as indexf4ID66DbAxMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/installmentPayments/index.vue?macro=true";
import { default as indexclljfO979RMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/payments/index.vue?macro=true";
import { default as indexxMy4YC9eOeMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/login/index.vue?macro=true";
import { default as valuesz31IwMNFrNMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/[id]/values.vue?macro=true";
import { default as indexn7GyUBecUPMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/index.vue?macro=true";
import { default as subcategoriesHPNDkoE3QsMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/[id]/subcategories.vue?macro=true";
import { default as indexTiRbJYexepMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/index.vue?macro=true";
import { default as indexD9a9b6t8eUMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/index.vue?macro=true";
import { default as indexjL0FE9T3wdMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/reports/index.vue?macro=true";
import { default as indexiOBAplJHuzMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/users/index.vue?macro=true";
import { default as indexfVzs4OIX6SMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/warehouses/index.vue?macro=true";
export default [
  {
    name: "brands",
    path: "/brands",
    meta: indexi505hxz1pNMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "companies",
    path: "/companies",
    meta: indexUO4gTW64OCMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-id-companies",
    path: "/customers/:id()/companies",
    meta: companiesfDtM5bqvcwMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/[id]/companies.vue").then(m => m.default || m)
  },
  {
    name: "customers-companies-id",
    path: "/customers/companies/:id()",
    meta: _91id_93jWOdy22J07Meta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers-companies",
    path: "/customers/companies",
    meta: indextCSkv68MfkMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    meta: index2MUHHltZAbMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexzYzxxR7bP9Meta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordpTZODeYQodMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexNy65mkjdGYMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id-payments",
    path: "/invoices/:id()/payments",
    meta: paymentsLYiovItf2kMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: index0yQWu5TCQ1Meta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-installmentPayments-id-details",
    path: "/invoices/installmentPayments/:id()/details",
    meta: detailsM5XduYWuNlMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/installmentPayments/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "invoices-installmentPayments",
    path: "/invoices/installmentPayments",
    meta: indexf4ID66DbAxMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/installmentPayments/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-payments",
    path: "/invoices/payments",
    meta: indexclljfO979RMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexxMy4YC9eOeMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "products-attributes-id-values",
    path: "/products/attributes/:id()/values",
    meta: valuesz31IwMNFrNMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/[id]/values.vue").then(m => m.default || m)
  },
  {
    name: "products-attributes",
    path: "/products/attributes",
    meta: indexn7GyUBecUPMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/index.vue").then(m => m.default || m)
  },
  {
    name: "products-categories-id-subcategories",
    path: "/products/categories/:id()/subcategories",
    meta: subcategoriesHPNDkoE3QsMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/[id]/subcategories.vue").then(m => m.default || m)
  },
  {
    name: "products-categories",
    path: "/products/categories",
    meta: indexTiRbJYexepMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    meta: indexD9a9b6t8eUMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexjL0FE9T3wdMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexiOBAplJHuzMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "warehouses",
    path: "/warehouses",
    meta: indexfVzs4OIX6SMeta || {},
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/warehouses/index.vue").then(m => m.default || m)
  }
]