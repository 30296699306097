import HttpFactory from '~/repository/factory'
import type { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { DASHBOARD, DASHBOARD_GENERAL } from '~/constants/apiPaths/dashboard/paths'

class GeneralInformationRepository extends HttpFactory {
  async getGeneralInformationForDashboard(extras: IExtrasFetch, searchCriteria: object, signal: AbortSignal | null = null): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${DASHBOARD}${DASHBOARD_GENERAL}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }
}
export default GeneralInformationRepository
