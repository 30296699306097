export const AUTH_STORE = 'useAuthStore'
export const CUSTOMER_STORE = 'useCustomerStore'
export const PRODUCT_STORE = 'useProductStore'
export const COMPANY_STORE = 'useCompanyStore'
export const SELECTED_COMPANY_FOR_WHOLE_APP = 'useSelectedCompanyForWholeAppStore'
export const LOCALIZATION_STORE = 'useLocalizationStore'
export const CATEGORY_STORE = 'useCategoryStore'
export const BRAND_STORE = 'usebrandStore'
export const ATTRIBUTE_STORE = 'useAttributeStore'
export const ATTRIBUTE_VALUE_STORE = 'useAttributeValueStore'
export const WAREHOUSE_STORE = 'useWarehouseStore'
export const SUB_CATEGORY_STORE = 'useSubCategoriesStore'
export const INVOICE_STORE = 'useInvoiceStore'
export const INVOICE_PAYMENTS_STORE = 'useInvoicePaymentsStore'
export const USER_STORE = 'useUserStore'
export const INVOICE_PAYMENT_TIMELINE_ITEMS_STORE = 'useInvoicePaymentTimelineItemsStore'
export const REPORT_STORE = 'useReportStore'
